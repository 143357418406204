// Service for Date formatting

const DateService = {

  // Return date in format: Day Month Year ( 24 August 2021 )
  format: (dateAsString) => {
    const date = new Date(dateAsString);
    return `${date.getDate()} ${date.toLocaleDateString('default', { month: 'long' })} ${date.getFullYear()}`;
  }
};

export default DateService;