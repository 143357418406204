// Dependency
import React from 'react';

// Styles
import * as styles from './section-title.module.scss';

// Template
const SectionTitle = (props) => {
  return (
      <div className={styles.sectionTitleWrapper}>
          {props.children}
          <h2 className={`${styles.sectionTitle}`} data-color={props.color}>
              <span>{props.title}</span>
          </h2>
      </div>
  );
};

export default SectionTitle;