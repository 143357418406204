// Dependency
import React, { useEffect } from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Link} from "gatsby";

// Service
import UtilsService from '../../services/utils.service';

//Internationalization
import { useTranslation } from "react-i18next";

// Styles
import * as headerCatsStyles from "./cats.module.scss"

// Template
const HeaderCats = (props) => {
    // constructor(props){
    //     super(props)
    // }
    const locale = UtilsService.getLocaleFromUrl();

    const { t } = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
            const topBorder = document
                .getElementById("cd-hero")
                .getBoundingClientRect().top;

            topBorder >= 0
                ? document.getElementById("cd-header-cats").classList.remove("cd-header-cats-fixed")
                : document.getElementById("cd-header-cats").classList.add("cd-header-cats-fixed");
        }
        window.addEventListener("scroll", handleScroll);
        return (() => {
            window.removeEventListener("scroll", handleScroll);
        })
    }, []);
    return (
        <div className={headerCatsStyles.cdHeaderCatsBar} id="cd-header-cats-bar">
            <nav className={headerCatsStyles.cdHeaderCats} id="cd-header-cats">
                <div className="cd-max-width">
                    <Swiper className={headerCatsStyles.cdHeaderCatsSwiper}
                            spaceBetween={16}
                            slidesPerView={'auto'}
                            grabCursor={true}
                            freeMode={true}
                    >
                        <SwiperSlide className={headerCatsStyles.swiperSlide}>
                            <Link className={headerCatsStyles.cdHeaderCat} to={`/${locale.country}/${t('slug.blog')}/top-stories/`}>{t('blog.topStories')}</Link>
                        </SwiperSlide>
                        {props && props.data && props.data.map( (HeaderPerk, index) => {
                            return (
                                <SwiperSlide key={index} className={headerCatsStyles.swiperSlide}>
                                    <Link className={headerCatsStyles.cdHeaderCat} to={`/${locale.country}/${t('slug.blog')}/${HeaderPerk.slug}/`}>{HeaderPerk.name}</Link>
                                </SwiperSlide>
                            )
                        } )}
                    </Swiper>
                </div>
            </nav>
        </div>
    );
    
}

export default HeaderCats