// Dependency
import React from 'react';
import { Link } from 'gatsby';
import Img from "gatsby-image";

// Services
import DateService from '../../../services/date.service';
import UtilsService from '../../../services/utils.service';

// Internationalization
import { useTranslation } from 'react-i18next';

// Components
import BlogPostAuthor from '../blog-post-author/blog-post-author';

// Styles
import * as styles from './blog-post-thumbnail-small.module.scss';

// Template
const BlogPostThumbnailSmall = ({ postItem, hasExcerpt }) => {
  // Get locale since we are using relative URLs
  const locale = UtilsService.getLocaleFromUrl();

  const { t } = useTranslation();

  return (
    <article className={`${styles.blogPostThumbnailSmall}`} key={`${postItem.id}`}>
      {postItem.featuredMedia && postItem.categories.length > 0 && <div className={`${styles.blogPostThumbnailSmall__img} cd-background-img`}>
        <Link to={`/${locale.country}/${t('slug.blog')}/${postItem.categories[0].slug}/${postItem.seoSlug}/`}>
          <Img fluid={postItem.featuredMedia.fluid} alt={postItem.featuredMedia.title} />
        </Link>
      </div>
      }
      <div className={`${styles.blogPostThumbnailSmall__content}`}>
        <h1 className={`${styles.blogPostThumbnailSmall__title}`}>
          <Link to={`/${locale.country}/${t('slug.blog')}/${postItem.hasOwnProperty('categories') && postItem.categories.length > 0 ? postItem.categories[0].slug : 'flowlife'}/${postItem.seoSlug}/`}>{postItem.title}</Link>
        </h1>
        {hasExcerpt && postItem.excerpt && <div dangerouslySetInnerHTML={{ __html: postItem.excerpt }} />}
      </div>
      <BlogPostAuthor
        categories={postItem.categories}
        readingtime={postItem.readingTime}
        author={postItem.author.name}
        imageurl={postItem.author && postItem.author.featuredImage ? postItem.author.featuredImage.fluid : ''}
        date={postItem.date ? DateService.format(postItem.date) : ''}
      />
    </article>
  );
};

export default BlogPostThumbnailSmall;