// Dependency
import React from 'react';

// Components
import BlogPostThumbnailSmall from '../blog-post-thumbnail-small/blog-post-thumbnail-small';

// Template
const BlogPostGrid = ({ columns, data, hasExcerpt, limit, cname }) => {
  const template = data && data.map((element, index) => {
    if (limit) {
      if ( index < limit) {
        return <BlogPostThumbnailSmall key={element.id} postItem={element} hasExcerpt={hasExcerpt} />
      }
    } else {
      return <BlogPostThumbnailSmall key={element.id} postItem={element} hasExcerpt={hasExcerpt} />
    }
  });

  return (
    <div className={`${cname} columns`}  data-column-count={columns}>
      { template }
    </div>
  );
};

export default BlogPostGrid;