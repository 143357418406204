// Dependency
import React from 'react';
import Img from "gatsby-image";

// Styles
import * as styles from './blog-post-author.module.scss';

// Assets
import iconClock from '../assets/icon-clock.svg';

// Template
const BlogPostAuthor = ({ author, categories, readingtime, imageurl, date }) => {
  return (
    <div className={`${styles.blogPostAuthor} cd-dark-mode-dark`}>
      {imageurl &&
        <div className={`${styles.cdAuthorImage} cd-background-img`}>
          <Img fluid={imageurl} alt="" />
        </div>
      }
      <div>
        <div className={styles.cdBlogAuthorWrapper}>
          <p className={`${styles.blogPostAuthor__categories}`}>Av: <b>{author} &nbsp;i</b>
            {
              categories && categories.length > 0 && <b >{categories[0].name}</b>
            }
          </p>

        </div>
        {date && <span>{date}</span>}
        {readingtime && <span className={styles.blogPostAuthor__rt}>
          <img src={iconClock} alt="" />
          {readingtime}
        </span>
        }
      </div>
    </div>
  );
};

export default BlogPostAuthor;