// Dependency
import React from 'react';
import { Link } from 'gatsby';

// Service
import UtilsService from '../../services/utils.service';

// Internationalization
import { useTranslation } from 'react-i18next';

// Styles
import * as styles from './tags.module.scss';

// Template
const Tags = ({ categories }) => {
  // Get locale since we are using relative URLs
  const locale = UtilsService.getLocaleFromUrl();

  const { t } = useTranslation();

  return (
    <div className={`${styles.tags}`}>
      {
        categories && categories.map( (category) => {
          return <Link
            className={`${styles.tags__link}`}
            to={`/${locale.country}/${t('slug.blog')}/${category.slug}/`}
            key={category.id}
          >{category.name}</Link>
        })
      }
    </div>
  );
};

export default Tags;