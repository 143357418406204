// Dependency
import React from 'react';
import { Link } from 'gatsby';
import Img from "gatsby-image";

// Services
import DateService from '../../../services/date.service';
import UtilsService from '../../../services/utils.service';

//Internationalization
import { useTranslation } from 'react-i18next';

// Styles
import * as styles from './blog-post-list.module.scss';

// Assets
import iconClock from './assets/icon-clock.svg';

// Template
const BlogPostList = ({ data }) => {

  // Get locale since we are using relative URLs
  const locale = UtilsService.getLocaleFromUrl();

  const { t } = useTranslation();

  const list = data && data.map((element, index) => {
    return <article className={`${styles.blogPostList__item}`} data-index-number={'0' + (index + 1)} key={element.id}>
        <div>
          <div className={`${styles.blogPostList__info} cd-dark-mode-dark`}>
            { element.author.featuredImage &&
              <div className={`${styles.cdBlogListImg} cd-background-img`}>
                <Img fluid={element.author.featuredImage.fluid} alt="" />
              </div>
            }
            <span>Av: <b>{element.author.name}</b> i { element.categories && element.categories[0] && <b>{element.categories[0].name}</b>  }</span>
          </div>
          <h3 className={`${styles.blogPostList__title}`}>
            <Link to={`/${locale.country}/${t('slug.blog')}/${element.categories[0].slug}/${element.seoSlug}/`}>{ element.title }</Link>
          </h3>
          <p className={`${styles.blogPostList__meta}`}>
            { element.date && <span>{ DateService.format(element.date) }</span> }
            {
              element.readingTime && <span>
                <img src={iconClock} alt="" />
                { element.readingTime }
              </span>
            }
          </p>
        </div>
        {
          element.featuredMedia && <Link to={`/${locale.country}/${t('slug.blog')}/${element.categories[0].slug}/${element.seoSlug}/`} className={`${styles.cdBlogPostListThumb} cd-background-img`}>
            { element.featuredMedia.fluid && <Img fluid={element.featuredMedia.fluid} alt={element.featuredMedia.title} /> }
          </Link>
        }
      </article>
  });

  // Template
  return (
    <div className={`${styles.blogPostList}`}>
      { list }
    </div>
  );
};

export default BlogPostList;